@import './hdv/stylesheet.css';
@import './hdv-fillin/stylesheet.css';

:root {
    --terminal-font: 'IBM Dos ISO9';
    --base-fontsize: 18px;
    --terminal-font-ratio: calc(5/6);
}

@font-face {
    font-family: 'HumuTerm';
    /* src: url('./Web437_ToshibaSat_9x14.woff') format('woff'); */
    src: url('./Web437_Wyse700b.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    padding: 0;
    margin: 0;
    font-size: inherit;
}

body,
html {
    overflow: hidden;
    background: #050505;
    background: #111 url('../assets/static.gif') no-repeat center center;
    background-size: cover;
    font-family: Helvetica, Arial, sans-serif;
    min-height: 100vh;
    font-size: var(--base-fontsize);
}

body::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.1983;
}

main iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease;
}

.center {
    align-self: center;
}

#tui {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 1;
    color: #fff;
    cursor: default;
    background: rgba(0, 0, 0, .95);
    transition: all .1s cubic-bezier(0.400, 0.055, 0.460, 1.020);
}

#tui.light-mask {
    background: rgba(0, 0, 0, .6);
}

.glitch_1 {
    mix-blend-mode: overlay;
}

.glitch_2 {
    opacity: 0.9;
    filter: contrast(1) hue-rotate(5deg);
    transform: scaleX(var(--terminal-font-ratio)) skewX(3deg) !important;
}

@keyframes flicker {
    0% {
        opacity: 1;
    }
    96% {
        opacity: 1;
    }
    97% {
        opacity: 0.89;
    }
    98% {
        opacity: 1;
    }
    99% {
        opacity: 0.95;
    }
    100% {
        opacity: 1;
    }
}

#terminal {
    transition: all .06s cubic-bezier(0.400, 0.055, 0.460, 1.020);
    text-align: left;
    font-family: 'HumuTerm';
    transform: scaleX(var(--terminal-font-ratio));
    color: greenyellow;
    white-space: pre;
    text-shadow: 0 0 16px #3dff4a;
    transform-origin: center;
}
    #terminal article {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

#terminal.flicker-effect {
    animation: flicker 6s ease;
    animation-delay: 5s;
    animation-iteration-count: infinite;
}

.term-text {
    font-family: 'HumuTerm';
    color: greenyellow;
    white-space: pre;
    text-shadow: 0 0 16px #3dff4a;
    transform: scaleX(var(--terminal-font-ratio));
}

    button.launch {
        padding: 0;
        background: transparent;
        cursor: pointer;
        border: none;
        text-align: left;
    }

    #splash a.charts-link {
        border: 2px solid white;
        padding: 1rem 2rem;
        text-decoration: none;
    }

        #splash a.charts-link:hover {
            border: 2px solid tomato;
            color: tomato;
        }

#changelog {
    display: none;
    background: black;
    padding: 2rem;
    width: 25rem;
    text-align: left;
    margin: 0;
}

#changelog ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#changelog li span:before {
    content: '<';
}

#changelog li span:after {
    content: '> ';
}

html:-moz-fullscreen #splash.no-cursor {
    cursor: none !important;
}

html:-webkit-full-screen #splash.no-cursor {
    cursor: none !important;
}

html:fullscreen #splash.no-cursor {
    cursor: none !important;
}

#ui {
    position: absolute;
    right: 4rem;
    top: 3rem;
    z-index: 3;
    opacity: 0;
    transition: opacity .7s ease;
    pointer-events: none;
}

#ui.fadeIn {
    pointer-events: all;
}

#ui:hover nav {
    opacity: 1;
    transform: translateY(0px);
}

#ui nav {
    position: relative;
    opacity: 0;
    transition: all .420s ease;
    transform: translateY(-10px);
    user-select: none;
}

#ui nav ul {
    list-style-type: none;
}

#ui nav ul li {
    text-transform: uppercase;
    cursor: pointer;
}

#ui nav ul li span {
    color: tomato;
}

#ui nav ul li a {
    color: #fff;
}

#ui nav ul li:hover {
    opacity: .8;
}

#logo {
    cursor: default;
    text-transform: uppercase;
    transform: skewX(--terminal-font-ratio);
}

#youtube-logo {
    opacity: .1;
    position: absolute;
    right: 5rem;
    bottom: 2rem;
    width: 5.4rem;
    height: 5rem;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAxNiIgd2lkdGg9IjIwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9Ikljb25zLUFWIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDIuMDAwMDAwLCAtMTcxLjAwMDAwMCkiPjxnIGlkPSJ2aWRlby15b3V0dWJlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0Mi4wMDAwMDAsIDE3MS4wMDAwMDApIj48cGF0aCBkPSJNMTgsMC40IEMxNy40LDAuMiAxMy43LDAgMTAsMCBDNi4zLDAgMi42LDAuMiAyLDAuNCBDMC40LDAuOSAwLDQuNCAwLDggQzAsMTEuNiAwLjQsMTUuMSAyLDE1LjYgQzIuNiwxNS44IDYuMywxNiAxMCwxNiBDMTMuNywxNiAxNy40LDE1LjggMTgsMTUuNiBDMTkuNiwxNS4xIDIwLDExLjYgMjAsOCBDMjAsNC40IDE5LjYsMC45IDE4LDAuNCBMMTgsMC40IFogTTgsMTIuNSBMOCwzLjUgTDE0LDggTDgsMTIuNSBMOCwxMi41IFoiIGlkPSJTaGFwZSIvPjwvZz48L2c+PC9nPjwvc3ZnPg==);
    background-position: center right;
    background-size: 80px;
    background-repeat: no-repeat;
    z-index: 4;
    filter: invert(1);
    transition: all .3s ease;
    text-decoration: none;
}

    #youtube-logo.hidden {
        display: none;
    }

    #youtube-logo:hover {
        opacity: .8;
    }

        #youtube-logo p {
            display: flex;
            width: 25rem;
            height: 100%;
            position: absolute;
            right: 0;
            align-items: center;
            justify-content: right;
        }

            #youtube-logo p span {
                flex: 0 0 100%;
                position: relative;
                display: block;
                text-align: right;
                opacity: 0;
                transition: all .3s ease-out;
                color: black;
                line-height: 1.3rem;
                transform: translateX(-3rem);
                text-shadow: 1px 1px 1px #fff;
            }

            #youtube-logo:hover span {
                opacity: 1;
                transform: translateX(-6rem);
            }

.fadeIn {
    opacity: 1 !important;
}

.fadeOut {
    opacity: 0 !important;
}

@media (max-width: 1024px) {
    #ui {
        right: 2rem;
        top: 2rem;
        transform: scale(.8);
        transform-origin: top right;
    }

    #youtube-logo {
        right: 2rem;
        bottom: 1rem;
    }

    #title {
        transform: scale(.6);
    }

    #splash {
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 6rem;
    }

    #splash p {
        margin: 1rem 2rem;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    #splash {
        padding-top: 12rem;
    }
}
