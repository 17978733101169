@font-face {
  font-family: HVD Steinzeit;
  src: url("HVDSteinzeit.41fdf3b1.woff2") format("woff2"), url("HVDSteinzeit.5c4856a7.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: HVD Steinzeit Fill In;
  src: url("HVDSteinzeit-FillIn.09f973e3.woff2") format("woff2"), url("HVDSteinzeit-FillIn.fb033c17.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --terminal-font: "IBM Dos ISO9";
  --base-fontsize: 18px;
  --terminal-font-ratio: calc(5 / 6);
}

@font-face {
  font-family: HumuTerm;
  src: url("Web437_Wyse700b.eac799f1.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-size: inherit;
  margin: 0;
  padding: 0;
}

body, html {
  min-height: 100vh;
  font-family: Helvetica, Arial, sans-serif;
  font-size: var(--base-fontsize);
  background: #111 url("static.4d70382b.gif") center / cover no-repeat;
  overflow: hidden;
}

body:after {
  content: "";
  opacity: .1983;
  background-size: cover;
  position: absolute;
  inset: 0;
}

main iframe {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
}

.center {
  align-self: center;
}

#tui {
  z-index: 2;
  opacity: 1;
  color: #fff;
  cursor: default;
  background: #000000f2;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all .1s cubic-bezier(.4, .055, .46, 1.02);
  display: flex;
  position: absolute;
  inset: 0;
}

#tui.light-mask {
  background: #0009;
}

.glitch_1 {
  mix-blend-mode: overlay;
}

.glitch_2 {
  opacity: .9;
  filter: contrast() hue-rotate(5deg);
  transform: scaleX(var(--terminal-font-ratio) ) skewX(3deg) !important;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  96% {
    opacity: 1;
  }

  97% {
    opacity: .89;
  }

  98% {
    opacity: 1;
  }

  99% {
    opacity: .95;
  }

  100% {
    opacity: 1;
  }
}

#terminal {
  text-align: left;
  transform: scaleX(var(--terminal-font-ratio) );
  color: #adff2f;
  white-space: pre;
  text-shadow: 0 0 16px #3dff4a;
  transform-origin: center;
  font-family: HumuTerm;
  transition: all 60ms cubic-bezier(.4, .055, .46, 1.02);
}

#terminal article {
  flex-direction: column;
  justify-content: start;
  display: flex;
}

#terminal.flicker-effect {
  animation: flicker 6s 5s infinite;
}

.term-text {
  color: #adff2f;
  white-space: pre;
  text-shadow: 0 0 16px #3dff4a;
  transform: scaleX(var(--terminal-font-ratio) );
  font-family: HumuTerm;
}

button.launch {
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
}

#splash a.charts-link {
  border: 2px solid #fff;
  padding: 1rem 2rem;
  text-decoration: none;
}

#splash a.charts-link:hover {
  color: tomato;
  border: 2px solid tomato;
}

#changelog {
  width: 25rem;
  text-align: left;
  background: #000;
  margin: 0;
  padding: 2rem;
  display: none;
}

#changelog ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#changelog li span:before {
  content: "<";
}

#changelog li span:after {
  content: "> ";
}

html:-moz-fullscreen #splash.no-cursor, html:-webkit-full-screen #splash.no-cursor, html:fullscreen #splash.no-cursor {
  cursor: none !important;
}

#ui {
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: opacity .7s;
  position: absolute;
  top: 3rem;
  right: 4rem;
}

#ui.fadeIn {
  pointer-events: all;
}

#ui:hover nav {
  opacity: 1;
  transform: translateY(0);
}

#ui nav {
  opacity: 0;
  user-select: none;
  transition: all .42s;
  position: relative;
  transform: translateY(-10px);
}

#ui nav ul {
  list-style-type: none;
}

#ui nav ul li {
  text-transform: uppercase;
  cursor: pointer;
}

#ui nav ul li span {
  color: tomato;
}

#ui nav ul li a {
  color: #fff;
}

#ui nav ul li:hover {
  opacity: .8;
}

#logo {
  cursor: default;
  text-transform: uppercase;
  transform: skewX(--terminal-font-ratio);
}

#youtube-logo {
  opacity: .1;
  width: 5.4rem;
  height: 5rem;
  z-index: 4;
  filter: invert();
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAxNiIgd2lkdGg9IjIwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9Ikljb25zLUFWIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDIuMDAwMDAwLCAtMTcxLjAwMDAwMCkiPjxnIGlkPSJ2aWRlby15b3V0dWJlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0Mi4wMDAwMDAsIDE3MS4wMDAwMDApIj48cGF0aCBkPSJNMTgsMC40IEMxNy40LDAuMiAxMy43LDAgMTAsMCBDNi4zLDAgMi42LDAuMiAyLDAuNCBDMC40LDAuOSAwLDQuNCAwLDggQzAsMTEuNiAwLjQsMTUuMSAyLDE1LjYgQzIuNiwxNS44IDYuMywxNiAxMCwxNiBDMTMuNywxNiAxNy40LDE1LjggMTgsMTUuNiBDMTkuNiwxNS4xIDIwLDExLjYgMjAsOCBDMjAsNC40IDE5LjYsMC45IDE4LDAuNCBMMTgsMC40IFogTTgsMTIuNSBMOCwzLjUgTDE0LDggTDgsMTIuNSBMOCwxMi41IFoiIGlkPSJTaGFwZSIvPjwvZz48L2c+PC9nPjwvc3ZnPg==");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 80px;
  text-decoration: none;
  transition: all .3s;
  position: absolute;
  bottom: 2rem;
  right: 5rem;
}

#youtube-logo.hidden {
  display: none;
}

#youtube-logo:hover {
  opacity: .8;
}

#youtube-logo p {
  width: 25rem;
  height: 100%;
  justify-content: right;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
}

#youtube-logo p span {
  text-align: right;
  opacity: 0;
  color: #000;
  text-shadow: 1px 1px 1px #fff;
  flex: 0 0 100%;
  line-height: 1.3rem;
  transition: all .3s ease-out;
  display: block;
  position: relative;
  transform: translateX(-3rem);
}

#youtube-logo:hover span {
  opacity: 1;
  transform: translateX(-6rem);
}

.fadeIn {
  opacity: 1 !important;
}

.fadeOut {
  opacity: 0 !important;
}

@media (max-width: 1024px) {
  #ui {
    transform-origin: 100% 0;
    top: 2rem;
    right: 2rem;
    transform: scale(.8);
  }

  #youtube-logo {
    bottom: 1rem;
    right: 2rem;
  }

  #title {
    transform: scale(.6);
  }

  #splash {
    padding: 4rem 2rem 6rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  #splash p {
    margin: 1rem 2rem;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  #splash {
    padding-top: 12rem;
  }
}

/*# sourceMappingURL=index.e67cfb39.css.map */
